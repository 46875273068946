import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from './BusinessGuide.module.scss';
import Header from "../../components/Header/Header";
import MenuBar from "../../components/MenuBar/MenuBar";
import Footer from "../../components/Footer/Footer";
import Bener from "../../components/Bener/Bener";
import Ready from "../../components/Ready/Ready";
import FixIcon from "../../components/FixIcon/FixIcon";
import { Helmet } from "react-helmet-async";

import page1 from "../../assets/BusinessGuide/BusinessGuide2/page1.jpg";

const BusinessGuide2 = () => {
	const menuContents = [
		{ title: "사업안내", url: "/BusinessGuide/intro" },
		{ title: "분양일정", url: "/BusinessGuide/plan" },
		{ title: "당첨자서류안내", url: "/BusinessGuide/documents" }
	];
	const [isScroll, setIsScroll] = useState(false);
	const { pathname } = useLocation(); // 현재 경로를 가져옴

	useEffect(() => {
		window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 최상단으로 이동
	}, [pathname]); // pathname이 변경될 때마다 실행

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 0) {
				setIsScroll(true);
			} else {
				setIsScroll(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={styles.container}>

					<Helmet>
					<title>평택브레인시티푸르지오 - 분양일정</title>
					<meta name="description" content="평택브레인시티푸르지오의 최신 분양일정을 확인하세요. 청약 일정, 분양 절차, 주요 조건을 명확히 안내하여 분양 준비에 필요한 정보를 제공합니다. 평택에서 새로운 라이프스타일을 시작할 기회를 놓치지 마세요. 푸르지오의 고급스러운 주거공간에서 미래를 계획해보세요." />
					<meta property="og:title" content="평택브레인시티푸르지오 - 분양일정" />
					<meta property="og:description" content="평택브레인시티푸르지오의 최신 분양일정을 확인하세요. 청약 일정, 분양 절차, 주요 조건을 명확히 안내하여 분양 준비에 필요한 정보를 제공합니다. 평택에서 새로운 라이프스타일을 시작할 기회를 놓치지 마세요. 푸르지오의 고급스러운 주거공간에서 미래를 계획해보세요." />
					<meta property="og:image" content="https://www.bunyang-114.com/Main1.png" />
					<meta property="og:url" content="https://www.bunyang-114.com/BusinessGuide/plan" />
					<meta name="twitter:title" content="평택브레인시티푸르지오 - 분양일정" />
					<meta name="twitter:description" content="평택브레인시티푸르지오의 최신 분양일정을 확인하세요. 청약 일정, 분양 절차, 주요 조건을 명확히 안내하여 분양 준비에 필요한 정보를 제공합니다. 평택에서 새로운 라이프스타일을 시작할 기회를 놓치지 마세요. 푸르지오의 고급스러운 주거공간에서 미래를 계획해보세요." />
					<meta name="twitter:image" content="https://www.bunyang-114.com/Main1.png" />
					<meta name="twitter:url" content="https://www.bunyang-114.com/BusinessGuide/plan" />
					</Helmet>   
			

			<Header isChanged={isScroll} />
			<FixIcon />

			<Bener title="사업개요" />

			<MenuBar contents={menuContents} />

						{/* <h1> 태그를 사용하여 페이지 제목 설정 (SEO 최적화) */}
						<h1 className={styles.screenReaderOnly}>평택브레인시티푸르지오 - 분양일정</h1>
						<p className={styles.screenReaderOnly}>평택브레인시티푸르지오의 최신 분양일정을 확인하세요. 청약 일정, 분양 절차, 주요 조건을 명확히 안내하여 분양 준비에 필요한 정보를 제공합니다. 평택에서 새로운 라이프스타일을 시작할 기회를 놓치지 마세요. 푸르지오의 고급스러운 주거공간에서 미래를 계획해보세요.
						</p>	

			<div className={styles.textBox}>
				<div>평택 브레인시티의 눈부신 가치 위에</div>
				<div>브레인시티 푸르지오의 새로운 자부심으로 찾아옵니다.</div>
			</div>

			<img className={styles.img4} src={page1} alt="평택브레인시티푸르지오-분양일정image2" />



			<Footer />
		</div>
	)
}

export default BusinessGuide2;
